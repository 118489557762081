import React from "react";
import "./styles.scss";
import { Link } from "gatsby";

export default function Main() {
    return <div className="header-main">
        <Link to={"/"}>
            <img className="logo" src={'/images/logo-full.svg'} alt={"Logo Ogólnopolskiego Dnia Widoczności Osób Transpłciowych"}/>
        </Link>
    </div>
}