module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-DJBYBDTJBR"],"gtagConfig":{"anonymize_ip":true,"cookie_expires":0},"pluginConfig":{"head":true,"respectDNT":true,"exclude":[],"origin":"https://www.googletagmanager.com","delayOnRouteUpdate":0}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Pierwszy Ogólnopolski Dzień Widoczności Osób Transpłciowych","short_name":"Pierwszy Ogólnopolski Dzień Widoczności Osób Transpłciowych","description":"Pierwsza odsłona Ogólnopolskiego Dnia Widoczności Osób Transpłciowych. Panele, warsztaty, manifestacja, after party. 25-26 marca 2023, CK Zamek, Poznań","lang":"pl","start_url":"/","background_color":"#ffffff","theme_color":"#480882","display":"standalone","icon":"src/images/icon.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"1acc000a87d6a44678660a0829d6b5fc"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
