import React, {useRef, useState} from "react";
import StandardHeader from "./components/StandardHeader";
import MobileHeader from "./components/MobileHeader";
import "./styles.scss"
import useClickOutside from "../../hooks/useClickOutside";
import { Link } from "gatsby";

export default function Header() {
    const [expanded, setExpanded] = useState(false)

    const navLinksDictionary = {
        "Manifest": "/manifest",
        "Program": "/program",
        "Paneliści_stki": "/panelisci_stki",
        "Mapa": "/mapa",
        "Nagrania": "/nagrania",
        "Organizacje": "/organizacje",
    }

    const navLinks = Object.entries(navLinksDictionary).map(([text, route], index) => <Link
        key={index} to={route} onClick={() => {setExpanded(false)}}>{text}
    </Link>)
    const refMobileHeader = useRef(null)
    const refMobileNav = useRef(null)
    useClickOutside([refMobileHeader, refMobileNav], () => setExpanded(false))
    return <div className="site-header-container">
    <nav className={`mobile-nav ${expanded ? "expanded" : ""}`} ref={refMobileNav}>
        {navLinks}
        <div className={"nav-end"}/>
    </nav>
    <header className={"site-header"}>  
        <StandardHeader navLinks={navLinks}/>
        <MobileHeader expanded={expanded} setExpanded={setExpanded} titleBarRef={refMobileHeader}/>
    </header>
    </div>
}