import React from "react"
import "./styles.scss"
import love from "../../images/transheart.svg";
import {Link} from "gatsby";
import useLinks from "../../hooks/useLinks";
import { IconLogoFacebook, IconLogoPomagamPl } from "../Icons";

export default function Footer({alts}) {
    const links = useLinks();
    return (
        <footer className={"site-footer"}>
            <div className="footer-contents">
                <span className={"icon-links"}>
                    <a href={links.facebook} aria-label="Facebook">
                        <IconLogoFacebook/>
                    </a>
                    <a href={links.pomagamPl} aria-label="Pomagam.pl">
                        <IconLogoPomagamPl/>
                    </a>
                </span>
                <p className="margin">Sfinansowano ze środków budżetowych Miasta Poznania</p>
                <p>© 2023 Fundacja Lambda Polska Wszelkie prawa zastrzeżone | <Link to={`/polityka-prywatnosci`}>Polityka prywatności</Link> | <a href="https://drive.google.com/drive/folders/1Jo1pLjR5K-3Fp2ndKwG_YsiKACYFY2x0?usp=share_link">Dla prasy, do pobrania</a></p>
                <p>e-mail: <a href="mailto:info@dzientrans.pl">info@dzientrans.pl</a> | tel: <a href="tel:+48609890570">+48 609 890 570</a></p>
                <p className={"credit"}>Made with <img src={love} alt={"love"}/> by cddlee</p>
            </div>
        </footer>
    )
}