exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-manifest-index-js": () => import("./../../../src/pages/manifest/index.js" /* webpackChunkName: "component---src-pages-manifest-index-js" */),
  "component---src-pages-mapa-index-js": () => import("./../../../src/pages/mapa/index.js" /* webpackChunkName: "component---src-pages-mapa-index-js" */),
  "component---src-pages-nagrania-index-js": () => import("./../../../src/pages/nagrania/index.js" /* webpackChunkName: "component---src-pages-nagrania-index-js" */),
  "component---src-pages-organizacje-index-js": () => import("./../../../src/pages/organizacje/index.js" /* webpackChunkName: "component---src-pages-organizacje-index-js" */),
  "component---src-pages-panelisci-stki-index-js": () => import("./../../../src/pages/panelisci_stki/index.js" /* webpackChunkName: "component---src-pages-panelisci-stki-index-js" */),
  "component---src-pages-polityka-prywatnosci-index-js": () => import("./../../../src/pages/polityka-prywatnosci/index.js" /* webpackChunkName: "component---src-pages-polityka-prywatnosci-index-js" */),
  "component---src-pages-program-index-js": () => import("./../../../src/pages/program/index.js" /* webpackChunkName: "component---src-pages-program-index-js" */)
}

